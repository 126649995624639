import DateUtils from 'boel/utils/DateUtils';

export default class Kabinestyringsstatus {
  static MarkAState(en_aftale) {
    en_aftale.background = '';
    en_aftale.background_1 = '';
    en_aftale.background_2 = '';
    en_aftale.background_3 = '';

    if (en_aftale.outside) {
      if (
        en_aftale.room &&
        en_aftale.committed &&
        !en_aftale.finished
      ) {
        var now = new Date();
        var Previous = DateUtils.AddMinutes(now, -7);
        if (Previous >= new Date(en_aftale.committed)) {
          en_aftale.background_2 = 'bg-danger';
        }
      }
      if (en_aftale.background_2 != 'bg-danger') {
        if (en_aftale.room && en_aftale.finished && !en_aftale.done) {
          var now = new Date();
          var Previous = DateUtils.AddMinutes(now, -5);
          if (Previous >= new Date(en_aftale.finished)) {
            en_aftale.background_1 = 'bg-danger';
          }
        }
        // else if (en_aftale.room && en_aftale.finished && en_aftale.done)
        // {
        //   var now = new Date();
        //   var Previous = DateUtils.AddMinutes(now, -5);
        //   if (Previous >= new Date(en_aftale.done)) {
        //     en_aftale.background_2 = 'bg-danger';
        //   }
        // }
        if (en_aftale?.action?.name == 'Vendes' || en_aftale?.action?.timeout) {
          var now = new Date();
          if (now >= new Date(en_aftale.done)) {
            en_aftale.background_3 = 'bg-danger';
          }
        }
        if (en_aftale?.action?.name == 'Vendt' && !en_aftale.finished) {
          en_aftale.background_2 = 'bg-danger';
        }
      }
    }
    return en_aftale;
  }
  static MarkStatus(aftaler) {
    // Validering af situation
    for (let en_aftale of aftaler) {
      en_aftale = Kabinestyringsstatus.MarkAState(en_aftale);
    }
    return aftaler;
  }
}
